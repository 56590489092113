import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { borderRadius } from 'styles/variables'
import { primaryColor, black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

import Heading from 'components/Heading'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${rgba(black, 0.15)};
  padding: ${spacing.large};

  ${largerThan.small`
    min-height: 300px;
  `};

  ${largerThan.large`
    min-height: 360px;
  `};

  ${largerThan.xLarge`
    min-height: 420px;
    padding: ${spacing.xLarge};
  `};
`

const Step = styled(Heading)`
  color: ${primaryColor};
  margin-bottom: ${spacing.medium};

  ${largerThan.large`
    margin-bottom: ${spacing.large};
  `};
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Description = styled.p`
  color: ${({ theme }) => theme.body};
  margin-bottom: 0;
`

const HelpCard = ({ step, heading, description }) => (
  <Container>
    <Step size={6} tag="span">
      0{step}
    </Step>
    <Title size={3}>{heading}</Title>
    <Description>{description}</Description>
  </Container>
)

export default HelpCard
