import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { borderRadius, animationTimes, animationCurve } from 'styles/variables'
import * as spacing from 'styles/spacing'
import { largerThan, smallerThan } from 'styles/media'
import { cover } from 'styles/helpers'

import RichText from 'components/RichText'
import Heading from 'components/Heading'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Pill from 'components/Pill'
import PillGroup from 'components/Pill/PillGroup'

const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.large};

  ${largerThan.small`
    grid-gap: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: ${spacing.xxLarge};
  `};

  ${largerThan.large`
    grid-gap: 3rem;
  `};

  ${largerThan.xLarge`
    grid-gap: 4rem;
  `};
`

const Content = styled.div`
  position: static;

  ${smallerThan.medium`
    max-width: 640px;
  `};
`

const Name = styled(Heading)`
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${spacing.scale(spacing.small, 1.5)};
  `};
`

const Description = styled.p`
  margin-bottom: ${spacing.medium};

  ${largerThan.medium`
    margin-bottom: ${spacing.large};
  `};

  ${largerThan.large`
    margin-bottom: ${spacing.xLarge};
  `};
`

const List = styled.div`
  margin-bottom: ${spacing.medium};

  ${largerThan.medium`
    margin-bottom: ${spacing.large};
  `};

  ${largerThan.large`
    margin-bottom: ${spacing.xLarge};
  `};
`

const FigureWrap = styled(Link)`
  display: block;
  position: relative;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  ${largerThan.medium`
    flex: 1;

    ${(props) =>
      props.reverse &&
      css`
        order: -1;
      `}
  `};

  > * {
    transition: transform ${animationTimes.base} ${animationCurve};
    ${cover('absolute')};
  }

  &:hover > * {
    transform: scale(1.05);
  }

  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;

    ${largerThan.small`
      padding-bottom: 35%;
    `};

    ${largerThan.medium`
      padding-bottom: 85%;
    `};

    ${largerThan.large`
      padding-bottom: 75%;
    `};
  }
`

const ServicePreview = ({ name, slug, photo, description, list, reverse }) => (
  <Section flushTop>
    <Wrapper>
      <Container>
        <Content>
          <Name size={2}>{name}</Name>
          <Description>{description}</Description>
          <List>
            <PillGroup>
              {list.map((listItem, i) => (
                <Pill label={listItem} key={i} />
              ))}
            </PillGroup>
          </List>
          <RichText>
            <p>
              <Link to={`/services/${slug}/`}>Learn more</Link>
            </p>
          </RichText>
        </Content>
        <FigureWrap reverse={reverse} to={`/services/${slug}/`}>
          {photo}
        </FigureWrap>
      </Container>
    </Wrapper>
  </Section>
)

export default ServicePreview
