import React from 'react'

import { SERVICES } from 'utils/constants'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import Help from 'components/services/Help'
import ServicePreview from 'components/services/ServicePreview'

function isEven(value) {
  return !(value % 2)
}

const Services = () => (
  <>
    <MetaTags title="Services | Custom software development in Montreal | Volume7" />
    <PageHeader
      heading={
        <>
          Services & <span>capabilities</span>
        </>
      }
      description="We work as an extension of your team and offer a wide range of software-related services."
    />
    {SERVICES.map((service, i) => (
      <ServicePreview
        key={i}
        name={service.name}
        description={service.description}
        photo={service.figure}
        list={service.list}
        slug={service.slug}
        reverse={isEven(i)}
      />
    ))}
    <Help />
  </>
)

export default Services
