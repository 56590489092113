import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { rgba } from 'polished'

import { animationTimes, animationCurve, borderRadius } from 'styles/variables'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

const Container = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.body};
  background-color: ${({ theme }) => rgba(theme.heading, 0.075)};
  border-radius: ${spacing.scale(borderRadius, 0.75)};
  padding: ${spacing.tiny} ${spacing.small};
  font-weight: ${fontWeights.medium};
  font-size: 0.8em;

  ${(props) =>
    props.actionable &&
    css`
      color: ${({ theme }) => theme.heading};
      transition: color ${animationTimes.base} ${animationCurve},
        background-color ${animationTimes.base} ${animationCurve};

      &:hover {
        background-color: ${({ theme }) => rgba(theme.heading, 0.125)};
      }
    `}

  ${largerThan.medium`
    font-size: 0.9em;
    padding: ${spacing.scale(spacing.tiny, 1.5)} ${spacing.scale(
    spacing.small,
    1.5
  )};
    border-radius: ${borderRadius};
  `};
`

const Pill = ({ label, link, ...rest }) => {
  const el = link ? Link : 'div'

  return (
    <Container actionable={link} to={link} as={el} {...rest}>
      {label}
    </Container>
  )
}

export default Pill
