import React from 'react'
import styled, { css } from 'styled-components'
import { string, node, oneOf } from 'prop-types'
import { stripUnit, rgba } from 'polished'

import { borderRadius } from 'styles/variables'
import { white, black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'
import { cover } from 'styles/helpers'
import { lineHeights } from 'styles/typography'

import Heading from 'components/Heading'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: ${stripUnit(borderRadius) * 1.25 + 'rem'};
  background-color: ${black};
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 20;
  padding: ${spacing.medium};
  display: flex;
  align-items: flex-end;
  ${cover('absolute')};

  ${largerThan.small`
    padding: ${spacing.large};
  `};

  ${largerThan.xLarge`
    padding: ${spacing.xLarge};
  `};

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: ${spacing.medium};

      ${largerThan.xLarge`
        padding: ${spacing.large};
      `};
    `}
`

const Name = styled(Heading)`
  color: ${white};
  line-height: ${lineHeights.tight};
  text-shadow: 0px 1px 2px ${rgba(black, 0.25)};
`

const Overlay = styled.div`
  background: linear-gradient(
    180deg,
    ${rgba(black, 0)} 0%,
    ${rgba(black, 0.5)} 100%
  );
  z-index: 10;
  ${cover('absolute')};
`

const Background = styled.div`
  ${cover('absolute')};

  > * {
    ${cover('absolute')};
  }
`

const IndustryCard = ({ name, photo, size }) => (
  <Container>
    <Content size={size}>
      <Name size={size === 'small' ? 4 : 3}>{name}</Name>
    </Content>
    <Overlay />
    <Background>{photo}</Background>
  </Container>
)

IndustryCard.propTypes = {
  name: string.isRequired,
  photo: node.isRequired,
  size: oneOf(['small']),
}

export default IndustryCard
