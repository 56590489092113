import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { INDUSTRIES } from 'utils/constants'

import { themes } from 'styles/theme'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import Section, { SectionBlock } from 'components/Section'
import IndustryCard from 'components/industries/IndustryCard'
import HelpCard from 'components/services/Help/HelpCard'
import Wrapper from 'components/Wrapper'
import { Grid, GridRow, GridColumn } from 'components/Grid'

export const HELP_ITEMS = [
  {
    heading: 'Bring new digital products & services to market',
    description:
      'We turn your product idea into reality by putting together a team of experts to plan, design, and build your project.',
  },
  {
    heading: 'Rebuild legacy applications using up-to-date technologies',
    description:
      'We rebuild applications from the ground up to increase performance and revenue opportunities.',
  },
  {
    heading: 'Enhance current systems or platforms',
    description:
      'We audit existing systems and make improvements for increased efficiency and better business results.',
  },
]

const Divider = styled.div`
  background-color: ${({ theme }) => theme.border};
  height: 1px;
`

const SectionHeading = styled(Heading)`
  margin-bottom: ${spacing.large};

  ${largerThan.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${largerThan.xLarge`
    margin-bottom: ${spacing.xxLarge};
  `};
`

const Industries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.medium};

  ${largerThan.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${largerThan.large`
    grid-template-columns: repeat(6, 1fr);
  `};
`

const Help = () => (
  <ThemeProvider theme={themes.dark}>
    <Section>
      <Wrapper>
        <SectionBlock>
          <SectionHeading size={4}>What we can help you with</SectionHeading>
          <Grid>
            <GridRow>
              {HELP_ITEMS.map((item, i) => (
                <GridColumn xs={12} sm={6} md={4}>
                  <HelpCard
                    step={i + 1}
                    heading={item.heading}
                    description={item.description}
                  />
                </GridColumn>
              ))}
            </GridRow>
          </Grid>
        </SectionBlock>
        <SectionBlock>
          <Divider />
        </SectionBlock>
        <SectionBlock>
          <SectionHeading size={4}>Across multiple industries</SectionHeading>
          <Industries>
            {INDUSTRIES.map((industry, i) => (
              <IndustryCard
                key={i}
                name={industry.name}
                slug={industry.slug}
                photo={industry.photo}
                size="small"
              />
            ))}
          </Industries>
        </SectionBlock>
      </Wrapper>
    </Section>
  </ThemeProvider>
)

export default Help
