import React, { Children } from 'react'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: -${spacing.tiny};

  ${largerThan.medium`
    margin: -${spacing.scale(spacing.small, 0.75)};
  `};
`

const Item = styled.div`
  padding: ${spacing.tiny};

  ${largerThan.medium`
    padding: ${spacing.scale(spacing.small, 0.75)};
  `};
`

const PillGroup = ({ children }) => (
  <Container>
    {Children.map(children, (child, i) => (
      <Item key={i}>{child}</Item>
    ))}
  </Container>
)

export default PillGroup
